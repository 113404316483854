@import 'utils';

.insights .heading {
  color: var(--color-white);
}

.insight {
  display: flex;
  align-items: center;
  gap: spacing(5);
  padding: spacing(4);
  background-color: var(--color-background-hex);
  margin-bottom: spacing(5);
  img {
    border-radius: 3px;
  }
}
