@import 'utils';
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include up(desktop) {
    flex-direction: row;
  }
  text-align: center;
  background-color: var(--color-brand-tangerine);
  color: var(--color-foreground-hex);
  padding: spacing(15);
  gap: spacing(7);
  .title {
    color: inherit;
  }
  p {
    margin-bottom: 0;
  }
}

.link {
  font-weight: 500;
  text-decoration: underline;
}
