@import 'utils';

.quote {
  @include flex-column;
  border: 1px solid var(--color-neutral-600);
  color: var(--color-white);
  padding: spacing(5);
  gap: spacing(5);
  width: 100%;
  svg {
    color: var(--color-brand-tangerine);
  }

  footer {
    margin-top: auto;
  }

  > .text {
    &:before,
    &:after {
      content: '\0022';
    }
  }
  .author {
    font-size: 14px;
    &:before {
      content: '\2011\0020';
    }
  }
}
