@import 'utils';

.omi_heading {
  --gutter: 30px;
  @include flex-column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  gap: var(--gutter);
  padding: var(--gutter);
  background-color: var(--color-polar);
  color: var(--color-foreground-hex);
  font-weight: 700;

  @include up(tablet) {
    @include flex-row;
    border-radius: 100px;
    text-align: left;
    padding: spacing(5) spacing(10);
    &.has_icon {
      padding: 0 var(--gutter) 0 0;
    }
  }

  .text {
    margin: 0;
  }
}
