@import 'utils';

.legal_note {
  p {
    font-size: 10px;
    line-height: 14px;
    margin: 0;
  }
  margin-bottom: spacing(6);
  padding-bottom: spacing(6);
}
