@import 'utils';

.quote {
  @include flex-column;
  border: 3px solid var(--color-picton-blue);
  padding: spacing(5);
  gap: spacing(5);
  border-radius: 5px;
  width: 100%;
  svg {
    color: var(--color-brand-tangerine);
  }

  footer {
    margin-top: auto;
  }

  > .text {
    &:before,
    &:after {
      content: '\0022';
    }
  }
  .author {
    font-size: 14px;
  }
}
