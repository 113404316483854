@import 'utils';

.quotes {
  color: var(--color-product-text);
  h2,
  h3,
  h4 {
    color: inherit;
  }

  @include flex-column;

  @include up(desktop) {
    padding: 60px;
  }

  .title {
    margin: spacing(5) 0;
  }

  .question {
    margin-top: spacing(5);
    order: 2;
  }
  .answers {
    $gap: 20px;
    @include flex-column;
    gap: $gap;
    @include up(tablet) {
      @include flex-row;
      flex-wrap: wrap;
      .answer {
        flex: 1 1 43%;
        &:first-child:nth-last-child(odd) {
          flex-basis: 100%;
        }
      }
    }
  }
}
