@import 'utils';

.article_section {
  background-color: var(--color-background-hex);
  margin: spacing(6) 0;
  b {
    font-weight: 700;
  }
}

.chart {
  .chart_caption {
    text-align: center;
  }

  .chart_image {
    width: 100%;
    height: auto;
    margin-bottom: spacing(6);
  }

  &.split {
    @include up(desktop) {
      .content {
        display: flex;
        align-items: center;
        gap: spacing(10);
        > div {
          flex: 1;
        }
      }

      .chart_image {
        margin-bottom: 0;
      }
    }
  }
}

.text {
  line-height: 32px;
}
