@import 'utils';

.omi_info {
  @include flex-column;
  gap: spacing(2);
}

.main {
  @include flex-column;
  gap: spacing(4);
  @include up(desktop) {
    @include flex-row;
  }
}

.section_main {
  display: block;
  @include up(desktop) {
    display: none;
  }
}

.section_aside {
  display: none;
  @include up(desktop) {
    display: block;
  }
}

.header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: spacing(2);
  justify-content: space-between;

  @include up(tablet) {
    display: grid;
    flex-direction: row;
    grid-template-columns: auto max-content;
  }

  a {
    height: 42px;
    width: 100%;
  }
}

.sidebar {
  gap: spacing(6);
  @include flex-column;
  min-width: 370px;

  @include down(phone) {
    .summary p {
      font-size: 14px;
    }

    .summary p:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }

  .join {
    display: none;
    @include up(desktop) {
      @include flex-column;
    }
  }
}

.title {
  color: var(--color-product-text);
}

.summary {
  background-color: var(--color-polar);
}

.main_rail {
  background-color: var(--color-brand-gartner-blue);
  padding: spacing(5);
}

.related_omis {
  @include flex-column;
  background-color: var(--color-polar);
  gap: spacing(4);
  padding: spacing(4);

  @include up(tablet) {
    flex-direction: row;

    > div {
      flex-basis: 33.3%;
    }
  }

  @include up(desktop) {
    @include flex-column;
  }
}
