@import 'utils';

.omi_card {
  @include flex-column;
  background-color: var(--color-background-hex);
  padding: spacing(4);
  position: relative;
}

.image_container {
  aspect-ratio: 30/9;
  margin-bottom: spacing(5);
  position: relative;
  width: 100%;

  img {
    border-radius: 8px;
    object-fit: cover;
    position: relative;
  }
}

.view_insights {
  width: max-content;
}

.bottom {
  @include flex-column;
  justify-content: space-between;
}
