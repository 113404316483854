@import 'utils';

.sidebar_section {
  padding: spacing(6);
  color: var(--color-product-text);
}

.heading {
  text-transform: uppercase;
}
