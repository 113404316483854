@import 'utils';

.report {
  @include flex-column;
  gap: spacing(4);
  @include up(tablet) {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    flex-flow: nowrap;
  }
}

.report_about {
  @include flex-column;
  max-width: 470px;
  gap: spacing(2);
}

.report_download {
  @include flex-column;
  button {
    width: fit-content;
  }
  @include up(tablet) {
    align-items: end;
  }
}

.disclaimer {
  margin-top: spacing(5);
  @include up(tablet) {
    width: max-content;
  }
  svg {
    vertical-align: top;
  }
}
