@import 'utils';

.links {
  color: var(--color-product-text);

  a {
    color: var(--color-product-text);
    display: inline-block;
    margin-bottom: spacing(3);
    margin-right: spacing(5);

    &:hover {
      color: var(--color-brand-gartner-blue);
    }
  }
}

.social {
  margin: spacing(5) 0;

  a {
    margin: 0 spacing(2) 0 0;
  }
}
