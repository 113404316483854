@import 'utils';

$variants: (
        success: (
                background: var(--color-utility-success-50),
                text: var(--color-brand-gartner-blue),
        ),
        warning: (
                background: var(--color-utility-warning-50),
                text: var(--color-brand-gartner-blue),
        ),
        error: (
                background: var(--color-utility-error-200),
                text: var(--color-background-hex),
        ),
        info: (
                background: var(--color-brand-gartner-blue),
                text: var(--color-background-hex),
        ),
        help: (
                background: var(--color-utility-success-50),
                text: var(--color-brand-gartner-blue),
        ),
);

.toast {
  align-items: center;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
  display: flex;
  gap: spacing(2);
  line-height: 29px;
  max-width: 820px;
  padding: spacing(4) spacing(6);
  transition: opacity 0.15s ease;

  p {
    line-height: 29px;
    width: fit-content;
  }

  svg {
    align-self: self-start;
    display: block;
  }
}

.button {
  align-self: baseline;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;

  svg {
    color: var(--color-black);
  }
}

@each $name, $values in $variants {
  .#{$name} {
    $textColor: map-get($values, text);
    background-color: map-get($values, background);
    color: $textColor;

    .icon {
      color: $textColor;
    }
  }
}

@keyframes enter {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.animate_enter {
  animation: enter 0.75s ease;
}

.animate_leave {
  opacity: 0;
}
