@import 'utils';

.main {
  margin: spacing(10) auto;
  max-width: 1440px;
  padding: 0 spacing(5);

  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: spacing(5);
  }

  @include up(desktop) {
    .breadcrumb {
      margin-bottom: 0;
    }
  }
}
