@import 'utils';

$colors: (
  lemon: (
    background: #ffad1a,
    text: #002856,
  ),
  surf: (
    background: #2bcdbc,
    text: #002856,
  ),
  light_link_blue: (
    background: #478eff,
    text: #002856,
  ),
  sky_blue: (
    background: #40b3e1,
    text: #002856,
  ),
  gartner_blue: (
    background: #002856,
    text: #85cdf5,
  ),
  light_sky_blue: (
    background: #85cdf5,
    text: #002856,
  ),
  link_blue: (
    background: #0052d6,
    text: #fff,
  ),
  light_blue_bkgd: (
    background: #f3f9fb,
    text: #002856,
  ),
  gartner_blue_tint: (
    background: #6e7f9e,
    text: #002856,
  ),
  tangerine: (
    background: #ed6926,
    text: #002856,
  ),
  steel_gray: (
    background: #6f7878,
    text: #002856,
  ),
  gray: (
    background: #d2d2d2,
    text: #002856,
  ),
  black: (
    background: #000000,
    text: #fff,
  ),
);

.icon {
  --size: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-brand-surf);
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  flex-shrink: 0;
  color: var(--color-brand-gartner-blue);
  svg {
    display: block;
  }
}

@each $name, $values in $colors {
  .#{$name} {
    background-color: map-get($values, background);
    color: map-get($values, text);
    fill: map-get($values, text);
  }
}
