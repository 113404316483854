@import 'utils';

.quotes {
  @include flex-column;
  color: var(--color-foreground-hex);
  margin: 25px 0;

  .title {
    margin: spacing(5) 0;
    color: inherit;
  }

  .question {
    margin: spacing(5) 0 0 0;
    order: 2;
  }

  .answers {
    $gap: spacing(5);
    @include flex-column;
    gap: $gap;
    @include up(tablet) {
      @include flex-row;
      flex-wrap: wrap;

      .answer {
        flex: 1 1 43%;
        &:first-child:nth-last-child(odd) {
          flex-basis: 100%;
        }
      }
    }
  }
}
